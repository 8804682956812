import Image from 'next/legacy/image';

import { Container, NextLink } from '@/atoms';
import { SliderProduct } from '@/molecules';
import { ProductBlock } from '@/organisms';

import { removeBaseURL } from '@/lib/utils';

const MarcasFlexible = ({ bloque, id, className }: any) => (
  <Container id={id} wrapper as="section" {...(className && { className })}>
    <ProductBlock title={bloque.titulo} headingType={bloque.headingType}>
      <SliderProduct
        extensions={['grid']}
        height="auto"
        arrowPosition="center"
        centerSlide
        outline={false}
        perPage={6}
        breakpoints={{
          1024: {
            perPage: 5,
          },
          768: {
            perPage: 4,
          },
          640: {
            perPage: 3,
          },
          360: {
            perPage: 2,
          },
        }}
        grid={{
          rows: bloque?.numCol ?? 2,
        }}
      >
        {bloque?.marcas?.map(
          (marca: any, marcaIndex: number) =>
            marca?.marca?.sourceUrl &&
            (marca?.link ? (
              <NextLink href={removeBaseURL(marca.link)} key={marcaIndex}>
                <a aria-label={marca.marca.sourceUrl}>
                  <Image
                    src={marca.marca.sourceUrl}
                    width={marca.marca.mediaDetails.width}
                    height={marca.marca.mediaDetails.height}
                    alt={marca.marca.altText ?? 'Marca'}
                  />
                </a>
              </NextLink>
            ) : (
              <Image
                key={marcaIndex}
                src={marca.marca.sourceUrl}
                width={marca.marca.mediaDetails.width}
                height={marca.marca.mediaDetails.height}
                alt={marca.marca.altText}
              />
            )),
        )}
      </SliderProduct>
    </ProductBlock>
  </Container>
);

export default MarcasFlexible;

MarcasFlexible.displayName = 'FlexibleMarcas';
